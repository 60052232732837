/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 lotus.gltf --transform
Author: Shiki (https://sketchfab.com/RyougiShiki)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/lotus-flower-by-geometry-nodes-398b0f0eafe640a2a3dec98305a8e1c3
Title: Lotus Flower by Geometry Nodes
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/lotus-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Flower_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_1.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_2.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_3.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_4.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_5.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_6.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_7.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_8.geometry} material={materials.Material} />
          <mesh geometry={nodes.Flower_Material_0_9.geometry} material={materials.Material} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/lotus-transformed.glb')
